import styled from "styled-components";
import ListItemIcon from "@mui/material/ListItemIcon";
import { device } from "./device";
import Dialog from "@mui/material/Dialog";
import { color } from "../src/Theme"
import Buttons from "./components/inputs/buttons/Buttons";
import { CircularProgress } from "@mui/material";
// export const ListIcon = styled.div`

//   & {
//     svg {
//       color: ${(props) => props.theme.primary};
//     }
    
//   }
 
// `;
export const ListIcon = styled.div`
min-width: 30px;
`
//Basic style
export const P = styled.p`
  margin: 5px;
  color: ${(props) => props.theme.theme.text.primary};
`;
export const Div = styled.div``;
export const Img = styled.img``;
export const A = styled.a`
    color:inherit;
    transition: 0.3s;
    &:hover{
        color:${color.Primary_Color};
        transition: 0.3s;
    }
`;
export const BR = styled.br``;
export const H1 = styled.h1`
  color: ${color.Sidebar_Title};
`;
export const H2 = styled.h2``;
export const H3 = styled.h3`
    display: flex;
    align-items: center;
`;
export const H4 = styled.h4``;
export const H5 = styled.h5``;
export const H6 = styled.h6``;
export const Hr = styled.hr`
display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${color.Hr_Color};
    margin: 0.5em 0;
    padding: 0;`;
export const Span = styled.span``;
export const Ul = styled.ul``;
export const Li = styled.li``;
export const Label = styled.label``;
export const Bold = styled.b`
  color: ${(props) => props.theme.theme.text.bold};
`;

export const FormHr = styled.hr`
  margin: 0px 24px;
`;

export const RowDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size:13px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ColumnDiv1 = styled.div`
  flex: 50%;
  @media screen and (max-width: 992px) {
    flex: 50%;
  }
  && {
    p {
      margin-bottom: 20px;
      font-weight: bold;
    }
    span {
      color: black;
      font-weight: bold;
    }
  }
`;

export const FormTitleRight = styled.div`
  justify-content: right;
  align-items: center;
  display: flex;

  flex: 50%;
  @media screen and (max-width: 992px) {
    flex: 50%;
  }
`;

export const FormTitleLeft = styled.div`
  align-items: center;
  display: flex;
  flex: 50%;
  @media screen and (max-width: 992px) {
    flex: 50%;
  }
`;
export const ImageSection = styled.div`

background-color: #f6f7f8;
border-style: dashed;
border-color: gray;
display: flex;
justify-content: center;
align-items: center;
height: 200px;
@media only screen and (max-width: 1250px) and (min-width: 1000px) {
  height: 300px;
}
`
export const ListImage = styled.img`
  border-radius: 50px;
   width: 50px;
   height: 50px;
   object-fit: cover;
`;

export const ColumnDiv2 = styled.div`
  flex: 50%;
  @media screen and (max-width: 992px) {
    flex: 50%;
  }
  @media screen and (min-width: 600px) {
    text-align: right;
  }
  && {
    p {
      margin-bottom: 20px;
      font-weight: bold;
    }
    span {
      color: black;
      font-weight: bold;
    }
  }
`;

export const EqualDivider = styled.div`
  display: flex;
  margin: 0.5rem;
  padding: 1rem;
  background: papayawhip;
  ${(props: any) => props.vertical && "flex-direction: column;"}

  > * {
    flex: 1;

    &:not(:first-child) {
      ${(props: any) => (props.vertical ? "margin-top" : "margin-left")}: 1rem;
    }
  }
`;

export const Child = styled.div`
  padding: 0.25rem 0.5rem;
  background: palevioletred;
`;

export const Error = styled.small`
  color: red;
`;

export const Form = styled.form`
  padding: 20px;
`;

interface Props {
  sidebar: any;
}
export const Container = styled.div<Props>`
  display: grid;
  height: 100vh;
  grid-template-columns:${(props: any) => (props.sidebar ? '0 1fr 1fr 1fr' : "0.8fr 1fr 1fr 1fr")};
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar nav nav nav"
    "sidebar main main main";

  @media only screen and (max-width: 2000px) and (min-width: 1500px) {
    grid-template-columns: ${(props: any) => (props.sidebar ? '0 1fr 1fr 1fr' : '0.6fr 1fr 1fr 1fr')};
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: ${(props: any) => (props.sidebar ? '0 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr')};
  }

  @media only screen and (max-width: 978px) {
    display: block;
  }
  @media print {
    display: block;
  }
`;

// Navbar Style

export const Navbar = styled.nav`
  background: ${(props) => props.theme.theme.nav.background};
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  @media only screen and (max-width:978px) {
    justify-content: space-between;
  }
`;

export const NavIcon = styled.div`
  display: none;

  @media only screen and (max-width: 978px) {
    display: inline;
  }

  &&& {
    i {
      font-size: 26px;
      color: ${color.Sidebar_Responsive_Menu_Icon};
    }
  }
`;

export const NavbarLeft = styled.div`
  @media only screen and (max-width: 480px) {
    display: none;
  }

  & {
    a {
      margin-right: 30px;
      text-decoration: none;
      color: #a5aaad;
      font-size: 15px;
      font-weight: 700;
    }

    .active_link {
      color: ${(props) => props.theme.primary};
      border-bottom: 3px solid ${(props) => props.theme.primary};
      padding-bottom: 12px;
    }
  }
`;

export const NavbarRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & {
    a {
      margin-left: 20px;
      text-decoration: none;

      & {
        i {
          color: #a5aaad;
          font-size: 16px;
          border-radius: 50px;
          background: #ffffff;
          box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
          padding: 7px;
        }
      }
    }
  }
`;

//Subnav Styles
export const SubNavbar = styled.nav`
  background: ${(props) => props.theme.nav.background};
  grid-area: nav;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 0 10px;
  margin-top: 60px;
`;

// Main Styles
export const MainDiv = styled.main`
  background: ${(props) => props.theme.background};
  grid-area: main;
  overflow-y: auto;
  @media print{
    width: 100%;
    display: initial;
    overflow: auto;
  }
`;

export const MainContainer = styled.div`
  padding: 10px;
  @media screen and (max-width:786px){
    padding: 10px 10px;
  }
`;

export const MainTitle = styled.div`
  display: flex;
  align-items: center;

  & {
    img {
      max-height: 100px;
      object-fit: contain;
      margin-right: 20px;
    }
  }
`;

export const MainGreeting = styled.div`
  & {
    h1 {
      font-size: 24px;
      color: ${(props) => props.theme.text.title};
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      font-weight: 700;
      color: ${(props) => props.theme.text.primary};
    }
  }
`;

export const MainCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin: 20px 0;

  @media only screen and (max-width: 855px) {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
`;

export const Card = styled.div<{ bgColor: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;

  padding: 25px;
  border-radius: 10px;
  background-image: ${(props: any) => props.bgColor};
  box-shadow: 5px 5px 13px #bcb9b9, -5px -5px 13px #ffffff;
  color:#fff;
  position:relative;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
`;

export const HomeCard = styled.div<{ bgColor: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;

  padding: 25px;
  border-radius: 10px;
  background-color: ${(props: any) => props.bgColor};
  box-shadow: 5px 5px 13px #bcb9b9, -5px -5px 13px #ffffff;
  color:#fff;
  position:relative;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
`;

export const EmptyCard = styled.div<{ bgColor: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;

  padding: 25px;
  border-radius: 10px;
  background-color: ${(props: any) => props.bgColor};
  box-shadow: 0px 4px 8px 0px #4318FF;
  color:#fff;
  position:relative;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
`;


export const UserCard = styled.div<{ bgColor: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;

  padding: 25px;
  border-radius: 10px;
  background-image: ${(props: any) => props.bgColor};
  box-shadow: 5px 5px 13px #bcb9b9, -5px -5px 13px #ffffff;
  color:#fff;
  position:relative;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
`;
export const UserCardInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & {
    span {
      font-size: 25px;
      position: absolute;
      top: 50%;
      right: 10%;
      transform: translateY(-50%);
      color: ${color.Card_Count_Color};
    }
  }
`;

export const CardInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & {
    span {
      font-size: 25px;
      position: absolute;
      top: 50%;
      right: 10%;
      transform: translateY(-50%);
      color: ${color.Card_Count_Color};
    }
  }
`;
export const ServiceCard = styled.div<{ bgColor: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;

  padding: 25px;
  border-radius: 10px;
  background-color: ${(props: any) => props.bgColor};
  // box-shadow: 5px 5px 13px #bcb9b9, -5px -5px 13px #ffffff;
  // color:#fff;
  position:relative;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 10%);
`;

export const ServiceCardInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & {
    p {
      font-size: 20px;
      // position: absolute;
      // top: 50%;
      // right: 10%;
      // transform: translateY(-50%);
      // color: ${color.service_card_text_color};
    }
    & {
      :hover{
        color:${color.Primary_Color};
        transition: 0.3s;
        cursor: pointer;
      }
    }
  }
`;

// Sidebar Styles
interface Prop {
  opened: boolean;
}

export const SidebarDiv = styled.div<Prop>`
  background: ${color.Sidebar_Background};
  grid-area: sidebar;
  overflow-y: auto;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  &::-webkit-scrollbar-thumb {
    background: ${color.Sidebar_List_Row_Highlighted};
  }

  @media only screen and (max-width: 978px) {
    display: block;
    width: ${(prop: any) => (prop.opened ? "50%" : "0%")};
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }

  @media only screen and (max-width: 640px) {
    width: ${(props: any) => (props.opened ? "70%" : "0%")};
  }
`;

export const SidebarResponsive = styled.div``;

export const SidebarTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  position: relative;

  & {
    i {
      font-size: 18px;
      display: none;
    }

    div {
      img {
        width: 75px;
        object-fit: contain;
      }
      h1 {
        font-size: 18px;
        display: inline;
      }
    }
  }
  & {
    svg {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      @media only screen and (max-width: 978px) {
        display: block;
      }
    }
  }

  // @media only screen and (max-width: 978px) {
  //   display: inline;
  // }
`;

export const SidebarMenu = styled.div`
  & {
    h2 {
      color: #3ea175;
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 5px;
      padding: 0 10px;
      font-weight: 700;
    }
  }
`;
export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px 30px;
  gap: 20px 30px;
  padding-bottom: 30px;
`;
export const AlignLeft = styled.div`
  margin-right: auto;
`;

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Mainbox = styled.div`
  // width:90%;
  display: flex;
  // border-radius: 10px;
  // padding:10px;
  // margin-left: 20px;
  background-color: white;
  height: 550px;

  // @media only screen and (max-width: 978px) {
  //   flex-direction: column;
  // }
`;
export const Formcolum1 = styled.div`
  width: 500px;
  hight: 100%;
  padding: 15px;
`;
export const Formcolum2 = styled.div`
      width:500px;
      height: 100%
      padding:15px;
`;
export const Name = styled.h1`
  font-size: large;
  padding: 10px;
`;
// export const Butt=styled.select`
//      margin: left;
// `
export const Box = styled.div`
  width: 80%;
  height: 35vh;
  border: 1px solid #cec5c5;
  margin-left: 50px;
  border-radius: 10px;
  text-align: center;
  background-color: #dcdcdc;
`;
export const Input = styled.input`
  margin-top: 10px;
  width: 300px;
  height: 50px;
`;
export const Left = styled.div`
  float: left;
`;
export const DialogBox = styled.div``;
export const Right = styled.div`
  float: center;
  margin-left: 70px;
`;
export const H1tag = styled.h1`
  margin-left: 50px;
  font-size: large;
`;
export const But = styled.div`
  margin-left: 50px;
`;
export const Button = styled.button`
     margin-left: 50px;
     margin-top: 30px;
     background-color: #80d680;
     padding: 10px;
     border-radius: 5px;
     border: none;
     font-weight: bold;
     color: #020e02;
     cursor: pointer;
}`;

export const Outer = styled.div``;

export const padLefRig = styled.span`
  padding: 0px 20px 0px 20px;
`;

export const FlexDiv = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftDiv = styled.div`
  color: #fff;
  width: 410px;
  height: 300px;
  background-color: #c7c7c7;
  border-radius: 10px;
  margin: 30px 0px;
  text-align: center;
`;
export const RightDiv = styled.div`
  color: #fff;
  width: 410px;
  height: 300px;
  background-color: #c7c7c7;
  border-radius: 10px;
  margin: 30px 0px;
  text-align: center;
`;
export const DivWid = styled.div`
  width: 50%;
  margin: auto;
  padding-top: 30px;
`;
export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

// ingreduents

export const IngMainbox = styled.div`
  display: flex;
  width: 100%;
`;
export const IngFormcolum1 = styled.div`
  width: 500px;
`;
export const IngFormcolum2 = styled.div`
  width: 500px;
`;
export const IngName = styled.h1`
  font-size: large;
  margin: 15px 0px 10px 0px;
`;
export const Select = styled.select``;
export const IngH1tag = styled.h1`
  font-size: large;
  margin: 15px 0px 8px 0px;
  margin-left: 50px;
`;
export const IngBox = styled.div`
     width:90%;
     height: 40vh;
     border 1px solid #cec5c5;
     margin-left: 50px;
     border-radius:10px;
     text-align:center;
     background-color: #9e9e9e85;
     
`;
export const Upload = styled.div`
  margin-left: 50px;
  margin-top: 20px;
`;
export const IngBut = styled.div`
  margin-left: 50px;
  margin-top: 30px;
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
`;
export const gridRow = styled.div`
  display: grid;
`;
export const Divpad = styled.div`
  padding: 20px 30px;
`;

export const SubBox = styled.div``;

//Devide Column

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
  }
`;
export const Column = styled.div`
  width: 100%;
  padding: 10px;
  position: relative;
`;

// export const EmptyImgDiv = styled.div`
//   height: 260px;
//   width: 95%;
//   background: gray;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   border-radius: 10px;
// `;
export const ImageCollapse = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: inherit;
  overflow-x: hidden;
  width: 100%;
  row-gap: 10px;
  @media ${device.mobileXS} {
    grid-template-columns: 1fr;
  }
  @media ${device.mobileL} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
export const ImageBlock = styled.div`
  height: 125px;
  border-radius: 10px;
  object-fit: contain;
  cursor:pointer;
  position: relative;
  padding:10px 5px;
  @media ${device.mobileXS} {
    height: 150px;
  }
  @media ${device.tablet} {
    height: 125px;
  }
  &{
    img,video{
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        height:inherit;
        cursor:pointer;
    }
  }
`
export const ImageCollapseButtons = styled(Buttons)`
  background: white !important;
  transform: scale(0.5)
`;

export const PreviewImg = styled.img`
  width: 100%;
  max-height: 250px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  object-fit: contain;
  cursor:pointer;
`;

export const FormSubmitDiv = styled.div`
  width: 100%;
  justify-content: center;
  gap: 10px;
  display: flex;
`;
export const LoadingStyledComponent = styled.div`
  justify-content: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${color.Loading_bg};
`;
export const ProgressBarstyle = styled.div`
  // justify-content: center;
  // display: flex;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // align-self: center;
  // background-color: ${color.ProgressBar_bg};
`;
export const Loading1 = styled.div`
  display: flex;
  align-self: center;
  width: 50%;
  // display: flex;
  // align-self: center;
  // width: 50%;
`;

//Dialog Width
export const DialogDiv = styled(Dialog)`
  &&& {
    .css-19vroi4-MuiPaper-root-MuiDialog-paper {
      width: 100%;
    }
  }
`;
export const CloseIcon = styled.div`
  display: flex;
  margin-left: auto;
  width: 10%;
  color: ${color.Close_Icon};
`;
export const EmptyImgDiv = styled.div`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

export const BranchTitle = styled.h2`
  margin: 10px;
`;

export const DetailBlock = styled.div<{ show: any }>`
  background: #fff;
    box-shadow: 0 0 12px #bcb9b96e;
    border-radius: 5px 0 0 5px;
    width: ${(props: any) => props.show ? '400px' : '0'};
    height: auto;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10;
    transition: 0.3s;
    @media screen and (max-width:768px){
      width: ${(props: any) => props.show ? '80%' : '0'};
      top: 30%;
    }
`;

export const DetailHead = styled.h3`
padding: 10px;
    position: relative;
  border-bottom: 1px solid ${color.Hr_Color};
      margin-bottom: 10px;
      &{
        button{
          position: absolute;
          top:50%;
          transform: translateY(-45%);
        }
      }
`;

export const DetailBody = styled.div`
padding: 10px;
`;

export const Rangeinput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    background: #0095e8;
    width: 50%;
    cursor: pointer;
`;

export const CropperPopupDiv = styled.div`
      position: absolute;
      width: 100%;
      top: 0;
      height: 100vh;
`;

export const CropperControls = styled.div`
position: relative;
    width: 100%;
    bottom: 0;
    height: 15vh;
    background:white;
`;

export const CropBox = styled.div`
    position: relative;
    height: 85vh;
`;

export const ScrollToTopBtn = styled.button`
    border: none;
    background: ${color.Primary_Color};
    color: #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: auto;
    line-height: 1;
    position: absolute;
    bottom: 5%;
    right: 2%;
    cursor: pointer;
    &{
      :hover{
        color: #fff;
      }
    }
`;
export const NavBranch = styled.p`
    color:${color.Primary_Color};
        cursor: pointer;
    max-width: 500px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position:relative;
    font-size: 18px;
`;

export const NavLoading = styled(CircularProgress)`
    color:${color.Primary_Color};
`;

export const StripText = styled.span`
    text-decoration: line-through;
    margin: 0 10px;
    text-decoration-color: ${color.DANGER_COLOR};
    color:${color.DANGER_COLOR}
`;

export const ServiceCardBody = styled.div`
padding: 2rem 2.25rem;
flex: 1 1 auto;
    min-height: 1px;
`;
export const SubList = styled.div`
margin-left:40px;
background-color:#191927;
`;

export const SubContent = styled.div`
width:30%;
@media screen and (max-width:600px){
  width:100%;
  }

`;

export const FormDiv = styled.div`
width:70%;
@media screen and (max-width:600px){
width:100%;
}
`;

export const CategoryChild = styled.div`
margin-left:50px;
color:#575757;

`
export const CategoryParent = styled.div`
font-size:13px;
font-weight:bold;
padding-top:10px;
@media only screen and (max-width: 992px) and (min-width: 600px) {
  font-size:10px;
  font-weight:normal;
  }
`
export const CardDirection = styled.div`
display: flex;
justify-content: space-between;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`
export const DashboardTableHead = styled.th`
margin: 10px;
padding-top:20px;
font-weight: normal;

`
export const DashboardTableRow = styled.tr`
margin: 20px;
padding-top:20px;
font-weight: Bold;
font-size:20px;F
display: flex;
flex-wrap: wrap;
`
export const DashboardTable = styled.table`
margin: 20px;
padding-top:20px;
`
export const DashboardTableimg = styled.div`

`

export const VariantPriceQtySty = styled.div`
display:flex;
width:50%;
@media screen and (max-width:1024px){
  flex-direction:column ;
  width:100%;
}
`

export const VariantProportionSty = styled.div`
display:flex;
width:50%;
@media screen and (max-width:1024px){
  width:100%;
}
`

export const OrderDetailCardContent = styled.p`
font-size:13px`


export const FormBottomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    position: absolute;
    width: 100%;
    &{
        button{
            margin: 0 10px;
        }
    }
`;