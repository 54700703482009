export const lightTheme = {
    background : '#f3f4f6',
    nav: {
      background: "#fff"
    },
    primary: '#0095e8',
    text: {
      primary : "gray",
      bold: "black",
      title: "#111"
    },
  }

export const darkTheme = {
    background : '#333',
    nav: {
      background: "#1e1e2d"
    },
    primary: '#0095e8',
    text: {
      primary : "#fff",
      bold: "white",
      title: "#ffff",
    },
  }
  
 
  
  export const partyTheme = {
    backgroundColor: "#eeff00",
    textColor: "#ff88cc",
    primary: "#aa00ee"
  }