import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
    return ( <
        div className = "spinner-container" >
        <
        span className = "spinnerstyle loading-spinner " > < /span> { /* <span>Loading...</span> */ }

        <
        /div>
    );
}