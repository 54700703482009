import { refreshAccessToken } from "./authCrud";
import { language } from "../../helpers/Utils"
import { useEffect } from 'react';
import Axios from "axios";
import { HTTP_CODE } from "../ApiConst";
import { toast } from "react-toastify";
import { errorToastMsg, successMsg } from "../../helpers/Utils";


export default function setupAxios(
  axios,
  // store,
  base_url,
  firm,
  token,
  ref_token,
  post_id,
  session_id,
  config,
  auth
) {
  const ORG_AUTH_REFRESH = "/Org/Auth/Refresh/"

  function refreshAccessToken(data) {
    return Axios.post(ORG_AUTH_REFRESH, data);
  }
  axios.defaults.baseURL = base_url;

  // const language = localStorage.getItem("language");

  var refreshToken = localStorage.getItem("ref_token")
  // response interceptor
  axios.interceptors.response.use(undefined, (error) => {
    const statusCode = error.response ? error.response.status : null;
    statusResponse(statusCode, error.response);
    return Promise.reject(error);
  });

  const statusResponse = (code, data) => {
    const refTokenHeader = {
      headers: {
        // "Authorization": `Bearer ${authToken}`,
        "Accept-Language": language()
      }
    }

    if (code === 401) {
      if (refreshToken !== null) {
        axios.create(refTokenHeader).post("auth/refresh", { refresh_token: refreshToken })
          .then((resp) => {
            if (resp.status == HTTP_CODE.OK) {
              localStorage.setItem("token", resp?.data?.data?.access_token);
              localStorage.setItem("ref_token", resp?.data?.data?.refresh_token);
              window.reload()
            }
            else {
              localStorage.clear();
            }
          })
          .catch((e) => {
            localStorage.clear();
            window.location.href = "/";
            errorToastMsg(e.message);
          })
      } else {
        errorToastMsg(data.data.error);
      }
    }

    if (code >= 500) {
      errorToastMsg(data.data.message);
    }
    if (code === 400) {
      errorToastMsg(data?.data?.message);
    }
    if (code === 402) {
      errorToastMsg(data.data.message);
    }
    if (code === 424) {
      errorToastMsg(data.data.message);
    }
  };
  axios.interceptors.request.use(
    async (config) => {
      const authToken = localStorage.getItem("token");
      if (!authToken) {
        config.headers = {
          "accept-language": language(),
        };
        config.params = {
          "time_zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
          "company_id": 1
        }
      } else {
        config.headers = {
          // Authorization: "Basic " + localStorage.getItem("x-token"),
          "Authorization": `Bearer ${authToken}`,
          "Accept-Language": language()
        };
        config.params = {
          "time_zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
          "company_id": 1
        }
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }

  );
}
