import Axios from "../../../redux/setupAxios";


 const ORG_AUTH_LOGIN = "/auth/admin/login";
 export const ORG_AUTH_SIGNUP = "/Org/Auth/Signup/";
 const ORG_AUTH_FORGET_PASSWORD = "/Org/Auth/ForgotPassword/";
 const ORG_AUTH_RESEND_VERIFY_EMAIL = "/Org/Auth/Signup/ResendVerificationEmail/";
 const ORG_AUTH_UPDATE_PASSWORD = "/Org/Auth/UpdatePassword/";
 const ORG_AUTH_SIGNUP_RESEND_OTP = "/Org/Auth/Signup/ResendOTP/";
 const ORG_AUTH_VERIFY_OTP = "/Org/Auth/VerifyOTP/";
 const ORG_AUTH_FORGET_PASSWORD_VERIFY_OTP = "/Org/Auth/ForgotPassword/VerifyOTP/";
 const ORG_AUTH_FORGET_PASSWORD_VERIFY_EMAIL = "/Org/Auth/ForgotPassword/VerifyViaEmail/";
 const ORG_USER_STATIC_COUNTRY_CODE_GET = "/Org/User/StaticCountryCode/Get/";
 const ORG_AUTH_VERIFICATION_INPROGRESS ="Org/Auth/VerificationInProgress/";
 const ORG_AUTH_REFRESH = "/Org/Auth/Refresh/";
 const ORG_SERVICES_SERVICEINSTANCES_GET = "/Org/Services/ServiceInstances/Get/";
 const GET_COUNTRY = "/country/";
 const COMPANY_ID = process.env.REACT_APP_COMPANY_ID
export const ORG_AUTH_RESET_PASSWORD="/Org/Auth/ResetPassword/"



export function verificationInprogress(code) {
  return Axios.get(ORG_AUTH_VERIFICATION_INPROGRESS + code + "/");
}

export function getCountryList() {
  return Axios.get( GET_COUNTRY + "?page_size=1000");
}

export function verifyForgetPasswordEmail(email, otp) {
  return Axios.post( ORG_AUTH_FORGET_PASSWORD_VERIFY_EMAIL, { email: email, code: otp });
}

export function getServiceInstance(id) {
  return Axios.get( ORG_SERVICES_SERVICEINSTANCES_GET + id + "/");
}

export function authLogin(password, username, org_instance_id, fetchCountryId) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let regPhone = /^[+-]?\d*(?:[.,]\d*)?$/;
  if (regEmail.test(username)) {
    return Axios.post( ORG_AUTH_LOGIN, {
      email: username,
      password: password,
      type: "email",
      company_id: COMPANY_ID,
    });
  } else if (regPhone.test(username)) {
    return Axios.post(ORG_AUTH_LOGIN, {
      phn_nbr: username[0],
      password: password,
      type: "phn_nbr",
      company_id: COMPANY_ID,
      country_id:94,
    });
  } else {
    return Axios.post(ORG_AUTH_LOGIN, {
      username: username,
      password: password,
      type: "username",
      company_id: COMPANY_ID,
    });
  }
}

export function requestPassword(username, org_instance_id, fetchCountryId) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let regPhone = /^[+-]?\d*(?:[.,]\d*)?$/;
  if (regEmail.test(username)) {
    return Axios.post( ORG_AUTH_FORGET_PASSWORD, {
      email: username,
      company_id: COMPANY_ID,
    });
  } else if (regPhone.test(username)) {
    return Axios.post( ORG_AUTH_FORGET_PASSWORD, {
      phn_nbr: username[0],
      company_id: COMPANY_ID,
      country_id: fetchCountryId,
    });
  } else {
    return Axios.post( ORG_AUTH_FORGET_PASSWORD, {
      username: username,
      company_id: COMPANY_ID,
    });
  }
}
export function register(
  username,
  fname,
  lname,
  email,
  contry_code,
  phn_nbr,
  password,
  org_instance_id,
  node_id
) {
  return Axios.post(ORG_AUTH_SIGNUP, {
    // username: username,
    fname: fname,
    // lname: lname,
    email: email,
    country_id: contry_code,
    phn_nbr: phn_nbr,
    password: password,
    // org_instance_id:org_instance_id,
    // node_id: node_id,
  });
}

export function refreshAccessToken(refToken) {
  return Axios.post( ORG_AUTH_REFRESH, { refresh: refToken });
}

export function resendLinks(email) {
  return Axios.post( ORG_AUTH_RESEND_VERIFY_EMAIL, { email: email});
}
export function resendMblOTP(phn_nbr) {
  return Axios.post( ORG_AUTH_SIGNUP_RESEND_OTP, { mobilenumber: phn_nbr});
}
export function verifyMblOTP(data) {
  return Axios.post( ORG_AUTH_VERIFY_OTP, data);
}

export function forgetPasswordVerifyOTP(country_id,phn_nbr, OTP,org_instance_id) {
  return Axios.post( ORG_AUTH_FORGET_PASSWORD_VERIFY_OTP, {
    country_id:country_id,
    phn_nbr: phn_nbr,
    company_id: COMPANY_ID,
    code: OTP,
  });
}
export function resetPassword(values, token) {
  return Axios.post( ORG_AUTH_RESET_PASSWORD, {values,token:token});
}
