import Axios from "axios";
import { id } from "date-fns/locale";
import { language } from "../helpers/Utils";
const COMPANY_ID = process.env.REACT_APP_COMPANY_ID;
const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

// api response code 
export const HTTP_CODE = {
  ACCEPTED: 202,
  BADREQUEST: 400,
  CONFLICT: 409,
  CREATED: 201,
  OK: 200
};

const defaultOptions = {
  headers: {
    "Authorization": "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json",
    "Accept-Language": language()
  }
};
export const instance = Axios.create(defaultOptions);

export const ORG_AUTH_CHANGE_PASSWORD = "/auth/admin/change-password";

export function updatePassword(value:any) {
  return Axios.patch(ORG_AUTH_CHANGE_PASSWORD, value);
}

export const AUTH_GET_DETAILS_BY_TOKEN = "/auth/get-details-by-token"
export function getAuthDetailsByToken() {
  return Axios.get(AUTH_GET_DETAILS_BY_TOKEN);
}
// COUNTRY LIST API START 
export const COUNTRY_LIST = "/country/?page_size=1000"
export function getCountryList() {
  return Axios.get(COUNTRY_LIST);
}
// COUNTRY LIST API END 

// COUNTY LIST API START 
export const COUNTY_LIST = "/county"
export function getCountyListPassCountryId(value:any) {
  return Axios.get(COUNTY_LIST+'?country_id='+value);
}

// COUNTY LIST API END 

// VEHICLE BODY API START 

// LIST API 

export const VEHICLE_BODY_TYPE_LIST = "vehicle/body-type/list"

export function getVehicleBodyTypeList() {
  return Axios.get(VEHICLE_BODY_TYPE_LIST+"?page_size=1000");
}

// DATA TABLE API 
export const VEHICLE_BODY_TYPE_LIST_DATATABLE = VEHICLE_BODY_TYPE_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_BODY_TYPE_GET = "vehicle/body-type/get/"
export function getVehicleBodyTypeGet(id:any) {
  return Axios.get(VEHICLE_BODY_TYPE_GET + id);
}

// ADD API 
export const VEHICLE_BODY_TYPE_ADD = "vehicle/body-type/add"

export function postVehicleBodyTypeAdd(value:any) {
  return Axios.post(VEHICLE_BODY_TYPE_ADD, value);
}

// EDIT API

export const VEHICLE_BODY_TYPE_UPDATE = "vehicle/body-type/update/"
export function patchVehicleBodyTypeUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_BODY_TYPE_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_BODY_TYPE_DELETE = "vehicle/body-type/delete/"
export function deleteVehicleBodyTypeDelete(id:any) {
  return Axios.delete(VEHICLE_BODY_TYPE_DELETE + id);
}

// VEHICLE BODY API END



// VEHICLE MANUFACTURE API START 

// LIST API 
export const VEHICLE_MANUFACTURE_LIST = "vehicle/manufacture/list"
export function getVehicleManufactureList() {
  return Axios.get(VEHICLE_MANUFACTURE_LIST+"?page_size=1000");
}

// DATA TABLE API 
export const VEHICLE_MANUFACTURE_LIST_DATATABLE = VEHICLE_MANUFACTURE_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_MANUFACTURE_GET = "vehicle/manufacture/get/"
export function getVehicleManufactureGet(id:any) {
  return Axios.get(VEHICLE_MANUFACTURE_GET + id);
}

// ADD API 
export const VEHICLE_MANUFACTURE_ADD = "vehicle/manufacture/add"

export function postVehicleManufactureAdd(value:any) {
  return Axios.post(VEHICLE_MANUFACTURE_ADD, value);
}

// EDIT API

export const VEHICLE_MANUFACTURE_UPDATE = "vehicle/manufacture/update/"
export function patchVehicleManufactureUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_MANUFACTURE_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_MANUFACTURE_DELETE = "vehicle/manufacture/delete/"
export function deleteVehicleManufactureDelete(id:any) {
  return Axios.delete(VEHICLE_MANUFACTURE_DELETE + id);
}

// VEHICLE MANUFACTURE API END


// VEHICLE MODEL API START 

// LIST API 
export const VEHICLE_MODEL_LIST = "vehicle/model/list"
export function getVehicleModelList() {
  return Axios.get(VEHICLE_MODEL_LIST+"?page_size=1000");
}

// model passing Manufacture id api

export function getVehicleModelListPassManufactureId (id:any){
  return Axios.get(VEHICLE_MODEL_LIST+"?vehicle_manufacture_id="+ id);
}
// DATA TABLE API 
export const VEHICLE_MODEL_LIST_DATATABLE = VEHICLE_MODEL_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_MODEL_GET = "vehicle/model/get/"
export function getVehicleModelAdd(id:any) {
  return Axios.get(VEHICLE_MODEL_GET + id);
}

// ADD API 
export const VEHICLE_MODEL_ADD = "vehicle/model/add"

export function postVehicleModelAdd(value:any) {
  return Axios.post(VEHICLE_MODEL_ADD, value);
}

// EDIT API

export const VEHICLE_MODEL_UPDATE = "vehicle/model/update/"
export function patchVehicleModelUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_MODEL_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_MODEL_DELETE = "vehicle/model/delete/"
export function deleteVehicleModelDelete(id:any) {
  return Axios.delete(VEHICLE_MODEL_DELETE + id);
}

// VEHICLE MODEL API END




// VEHICLE ROOF-TYPE API START

// LIST API 
export const VEHICLE_ROOF_TYPE_LIST = "vehicle/roof-type/list"
export function getVehicleRoofTypeList() {
  return Axios.get(VEHICLE_ROOF_TYPE_LIST+"?page_size=1000");
}

// DATA TABLE API 
export const VEHICLE_ROOF_TYPE_LIST_DATATABLE = VEHICLE_ROOF_TYPE_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_ROOF_TYPE_GET = "vehicle/roof-type/get/"
export function getVehicleRoofTypeGet(id:any) {
  return Axios.get(VEHICLE_ROOF_TYPE_GET + id);
}

// ADD API 
export const VEHICLE_ROOF_TYPE_ADD = "vehicle/roof-type/add"

export function postVehicleRoofTypeAdd(value:any) {
  return Axios.post(VEHICLE_ROOF_TYPE_ADD, value);
}

// EDIT API

export const VEHICLE_ROOF_TYPE_UPDATE = "vehicle/roof-type/update/"
export function patchVehicleRoofTypeUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_ROOF_TYPE_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_ROOF_TYPE_DELETE = "vehicle/roof-type/delete/"
export function deleteVehicleRoofTypeDelete(id:any) {
  return Axios.delete(VEHICLE_ROOF_TYPE_DELETE + id);
}

// VEHICLE ROOF-TYPE API END




// VEHICLE SERIES API START

// LIST API 
export const VEHICLE_VARIANT_LIST = "vehicle/variant/list"
export function getVehicleVariantList() {
  return Axios.get(VEHICLE_VARIANT_LIST+"?page_size=1000");
}
// Variant passing model id api
export function getVehicleVariantListPassModelId (id:any){
  return Axios.get(VEHICLE_VARIANT_LIST+"?vehicle_model_id="+ id);
}

// DATA TABLE API 
export const VEHICLE_VARIANT_LIST_DATATABLE = VEHICLE_VARIANT_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_VARIANT_GET = "vehicle/variant/get/"
export function getVehicleVariantGet(id:any) {
  return Axios.get(VEHICLE_VARIANT_GET + id);
}

// ADD API 
export const VEHICLE_VARIANT_ADD = "vehicle/variant/add"

export function postVehicleVariantAdd(value:any) {
  return Axios.post(VEHICLE_VARIANT_ADD, value);
}

// EDIT API

export const VEHICLE_VARIANT_UPDATE = "vehicle/variant/update/"
export function patchVehicleVariantUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_VARIANT_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_VARIANT_DELETE = "vehicle/variant/delete/"
export function deleteVehicleVariantDelete(id:any) {
  return Axios.delete(VEHICLE_VARIANT_DELETE + id);
}

// VEHICLE SERIES API END


// VEHICLE TYPE-INFORMATION API START

// LIST API 
export const VEHICLE_TYPE_INFORMATION_LIST = "vehicle/type-information/list"
export function getVehicleTypeInformationList() {
  return Axios.get(VEHICLE_TYPE_INFORMATION_LIST+"?page_size=1000");
}
// DATA TABLE API 
export const VEHICLE_TYPE_INFORMATION_LIST_DATATABLE = VEHICLE_TYPE_INFORMATION_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_TYPE_INFORMATION_GET = "vehicle/type-information/get/"
export function getVehicleTypeInformationGet(id:any) {
  return Axios.get(VEHICLE_TYPE_INFORMATION_GET + id);
}

// ADD API 
export const VEHICLE_TYPE_INFORMATION_ADD = "vehicle/type-information/add"

export function postVehicleTypeInformationAdd(value:any) {
  return Axios.post(VEHICLE_TYPE_INFORMATION_ADD, value);
}

// EDIT API

export const VEHICLE_TYPE_INFORMATION_UPDATE = "vehicle/type-information/update/"
export function patchVehicleTypeInformationUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_TYPE_INFORMATION_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_TYPE_INFORMATION_DELETE = "vehicle/type-information/delete/"
export function deleteVehicleTypeInformationDelete(id:any) {
  return Axios.delete(VEHICLE_TYPE_INFORMATION_DELETE + id);
}


// VEHICLE TYPE-INFORMATION API END


// VEHICLE COLOURS API START

// LIST API 
export const VEHICLE_COLOURS_LIST = "vehicle/colours/list"
export function getVehicleColoursList() {
  return Axios.get(VEHICLE_COLOURS_LIST+"?page_size=1000");
}
// DATA TABLE API 
export const VEHICLE_COLOURS_LIST_DATATABLE = VEHICLE_COLOURS_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_COLOURS_GET = "vehicle/colours/get/"
export function getVehicleColoursGet(id:any) {
  return Axios.get(VEHICLE_COLOURS_GET + id);
}

// ADD API 
export const VEHICLE_COLOURS_ADD = "vehicle/colours/add"

export function postVehicleColoursAdd(value:any) {
  return Axios.post(VEHICLE_COLOURS_ADD, value);
}

// EDIT API

export const VEHICLE_COLOURS_UPDATE = "vehicle/colours/update/"
export function patchVehicleColoursUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_COLOURS_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_COLOURS_DELETE = "vehicle/colours/delete/"
export function deleteVehicleColoursDelete(id:any) {
  return Axios.delete(VEHICLE_COLOURS_DELETE + id);
}


// VEHICLE COLURS API END


// VEHICLE ENGINE CAPACITY API END

// LIST API 
export const VEHICLE_ENGINE_CAPACITY_LIST = "vehicle/engine-capacity/list"
export function getVehicleEngineCapacityList() {
  return Axios.get(VEHICLE_ENGINE_CAPACITY_LIST+"?page_size=1000");
}
// DATA TABLE API 
export const VEHICLE_ENGINE_CAPACITY_LIST_DATATABLE = VEHICLE_ENGINE_CAPACITY_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_ENGINE_CAPACITY_GET = "vehicle/engine-capacity/get/"
export function getVehicleEngineCapacityGet(id:any) {
  return Axios.get(VEHICLE_ENGINE_CAPACITY_GET + id);
}

// ADD API 
export const VEHICLE_ENGINE_CAPACITY_ADD = "vehicle/engine-capacity/add"

export function postVehicleEngineCapacityAdd(value:any) {
  return Axios.post(VEHICLE_ENGINE_CAPACITY_ADD, value);
}

// EDIT API

export const VEHICLE_ENGINE_CAPACITY_UPDATE = "vehicle/engine-capacity/update/"
export function patchVehicleEngineCapacityUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_ENGINE_CAPACITY_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_ENGINE_CAPACITY_DELETE = "vehicle/engine-capacity/delete/"
export function deleteVehicleEngineCapacityDelete(id:any) {
  return Axios.delete(VEHICLE_ENGINE_CAPACITY_DELETE + id);
}


// VEHICLE ENGINE CAPACITY API END


// VEHICLE  API START

// LIST API 
export const VEHICLE_LIST = "vehicle/list"
export function getVehicleList(data:any) {
  if(data !== null && data !== undefined){
  return Axios.get(VEHICLE_LIST+"?page_size=1000");
} else{
  return Axios.get(VEHICLE_LIST+"?page_size=1000&operational-status=Ready-to-Rent");
}
}

export function getRepalcementVehicleList() {
  return Axios.get(VEHICLE_LIST+ "?page_size=1000&operational-status=Ready-to-Rent");
}

export function getRendalVehicleList() {
  return Axios.get(VEHICLE_LIST+"?page_size=1000&operational-status=Ready-to-Rent");
}
// DATA TABLE API 
export const VEHICLE_LIST_DATATABLE = VEHICLE_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// export const VEHICLE_MAKE_COUNT= VEHICLE_LIST + "?vehicle_manufacture_name?id=";
// export function getVechicleCount(id: string, value: any) {
//   return Axios.patch(VEHICLE_LIST + "?vehicle_manufacture_name=" + id, value);
// }

// GET API 
export const VEHICLE_GET = "vehicle/get/"
export function getVehicleGet(id:any) {
  return Axios.get(VEHICLE_GET + id);
}



export function viewVehicle(id:any) {
  return Axios.get(VEHICLE_GET + id);
}

// ADD API 
export const VEHICLE_ADD = "vehicle/add"

export function postVehicleAdd(value:any) {
  return Axios.post(VEHICLE_ADD, value);
}

// EDIT API

export const VEHICLE_UPDATE = "vehicle/update/"
export function patchVehicleUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_DELETE = "vehicle/delete/"
export function deleteVehicleDelete(id:any) {
  return Axios.delete(VEHICLE_DELETE + id);
}


// STATUS UPDATE  API

export const VEHICLE_STATUS_UPDATE = "vehicle/status/update/"
export function patchVehicleStatusUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_STATUS_UPDATE + id, value);
}


// VEHICLE  API END




// VEHICLE INSURANCE_FINANCE_COMPANY API START

// LIST API 

export const VEHICLE_INSURANCE_FINANCE_COMPANY_LIST = "vehicle/insurance/finance/company/list"
export function getVehicleInsuranceFinanceCompanyList() {
  return Axios.get(VEHICLE_INSURANCE_FINANCE_COMPANY_LIST+"?page_size=1000");
}

// insurance list 

export function getVehicleInsuranceList() {
  return Axios.get(VEHICLE_INSURANCE_FINANCE_COMPANY_LIST+"?type=insurance&page_size=1000");
}

// insurance list 

export function getVehicleFinanceList() {
  return Axios.get(VEHICLE_INSURANCE_FINANCE_COMPANY_LIST+"?type=finance&page_size=1000");
}

// DATA TABLE API 
export const VEHICLE_INSURANCE_FINANCE_COMPANY_LIST_DATATABLE = VEHICLE_INSURANCE_FINANCE_COMPANY_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_INSURANCE_FINANCE_COMPANY_GET = "vehicle/insurance/finance/company/get/"
export function getVehicleInsuranceFinanceCompanyGet(id:any) {
  return Axios.get(VEHICLE_INSURANCE_FINANCE_COMPANY_GET + id);
}

// ADD API 
export const VEHICLE_INSURANCE_FINANCE_COMPANY_ADD = "vehicle/insurance/finance/company/add"

export function postVehicleInsuranceFinanceCompanyAdd(value:any) {
  return Axios.post(VEHICLE_INSURANCE_FINANCE_COMPANY_ADD, value);
}

// EDIT API

export const VEHICLE_INSURANCE_FINANCE_COMPANY_UPDATE = "vehicle/insurance/finance/company/update/"
export function patchVehicleInsuranceFinanceCompanyUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_INSURANCE_FINANCE_COMPANY_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_INSURANCE_FINANCE_COMPANY_DELETE = "vehicle/insurance/finance/company/delete/"
export function deleteVehicleInsuranceFinanceCompanyDelete(id:any) {
  return Axios.delete(VEHICLE_INSURANCE_FINANCE_COMPANY_DELETE + id);
}


// VEHICLE INSURANCE_FINANCE_COMPANY API END


// VEHICLE PAYMENT API START 

// LIST API 

export const VEHICLE_PAYMENT_LIST = "payment/account/list"

export function getVehiclePaymentList() {
  return Axios.get(VEHICLE_PAYMENT_LIST+"?page_size=1000");
}

// DATA TABLE API 
export const VEHICLE_PAYMENT_LIST_DATATABLE = VEHICLE_PAYMENT_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export const VEHICLE_PAYMENT_GET = "payment/account/get/"
export function getVehiclePaymentGet(id:any) {
  return Axios.get(VEHICLE_PAYMENT_GET + id);
}

// ADD API 
export const VEHICLE_PAYMENT_ADD = "payment/account/add"

export function postVehiclePaymentAdd(value:any) {
  return Axios.post(VEHICLE_PAYMENT_ADD, value);
}

// EDIT API

export const VEHICLE_PAYMENT_UPDATE = "payment/account/update/"
export function patchVehiclePaymentUpdate(id:any, value:any) {
  return Axios.patch(VEHICLE_PAYMENT_UPDATE + id, value);
}

// DELETE API 
export const VEHICLE_PAYMENT_DELETE = "payment/account/delete/"
export function deleteVehiclePaymentDelete(id:any) {
  return Axios.delete(VEHICLE_PAYMENT_DELETE + id);
}

// VEHICLE Payment API END

// VEHICLE PERODIC API

export const VEHICLE_PERIODIC_ANNUAL = "vehicle/periodic-month-type"

export const VEHICLE_PERIODIC_QUARTERLY = "vehicle/periodic-quarterly-type"




// CUSTOMERS   API START

// LIST API 
export const CUSTOMERS_LIST = "customers/list"
export const OWNER_LIST = "owners/list"
export const MECHANIC_LIST = "owners/list"
export function getCustomersList() {
  return Axios.get(CUSTOMERS_LIST+"?page_size=1000");
}
// DATA TABLE API 
export const CUSTOMERS_LIST_DATATABLE = CUSTOMERS_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

// GET API 
export function getListOwner() {
  return Axios.get(CUSTOMERS_LIST+"?type=owner");
}

export function getListCustomer() {
  return Axios.get(CUSTOMERS_LIST+"?type=customer"+"&page_size=1000");
}

export function getListMechanicic() {
  return Axios.get(CUSTOMERS_LIST+"?type=mechanic"+"&page_size=1000");
}

// GET API 
export const CUSTOMERS_GET = "customers/get/"
export function getCustomersGet(id:any) {
  return Axios.get(CUSTOMERS_GET + id);
}

// ADD API 
export const CUSTOMERS_ADD = "customers/add"

export function postCustomersAdd(value:any) {
  return Axios.post(CUSTOMERS_ADD+"?type=customer", value);
}

export function postOwnerAdd(value:any) {
  return Axios.post(CUSTOMERS_ADD, value);
}


export function numberOfOwners(){
  return Axios.get(CUSTOMERS_LIST+"?type=owner");
}
// EDIT API

export const CUSTOMERS_UPDATE = "customers/update/"
export function patchCustomersUpdate(id:any, value:any) {
  return Axios.patch(CUSTOMERS_UPDATE + id, value);
}


export function patchOwnersUpdate(id:any, value:any) {
  return Axios.patch(CUSTOMERS_UPDATE + id+"?type=owner", value);
}

// DELETE API 
export const CUSTOMERS_DELETE = "customers/delete/"
export function deleteCustomersDelete(id:any) {
  return Axios.delete(CUSTOMERS_DELETE + id);
}


// FUEL TYPE START 
export const VEHICLE_FUEL_TYPE = "vehicle/fuel-type"
export function getVehicleFuelTypeList() {
  return Axios.get(VEHICLE_FUEL_TYPE+"?page_size=1000");
}

// FUEL TYPE END 

// TRANSMISSION TYPE START 
export const VEHICLE_TRANSMISSION_TYPE = "vehicle/transmission-type"
export function getVehicleTransmissionTypeList() {
  return Axios.get(VEHICLE_TRANSMISSION_TYPE+"?page_size=1000");
}

// TRANSMISSION TYPE  END 

// CONDITION TYPE START 
export const VEHICLE_CONDITION_TYPE = "vehicle/condition-type"
export function getVehicleConditionTypeList() {
  return Axios.get(VEHICLE_CONDITION_TYPE+"?page_size=1000");
}

// CONDITION TYPE  END 

export const VEHICLE_RENTAL_STATUS  ="vehicle/rental-status"
export function getVehicleRentalStatus() {
  return Axios.get(VEHICLE_RENTAL_STATUS+"?page_size=1000");
}

export const VEHICLE_PERIODIC_TYPE  ="vehicle/periodic-type"
export function getVehiclePeriodicType() {
  return Axios.get(VEHICLE_PERIODIC_TYPE+"?page_size=1000");
}

export const VEHICLE_OWNER_TYPE  ="vehicle/owner_type"
export function getVehicleOwnerType() {
  return Axios.get(VEHICLE_OWNER_TYPE+"?page_size=1000");
}

// /customers/documents

// LIST API 
export const CUSTOMERS_DOCUMENTS_LIST = "customers/documents/list"
export function getCustomersDocumentsList(id:any) {
  return Axios.get(CUSTOMERS_DOCUMENTS_LIST+"?customer_id="+id);
}

// EDIT API

export const CUSTOMERS_DOCUMENTS_ADD = "customers/documents/add"
export function postCustomersDocumentsAdd( value:any) {
  return Axios.post(CUSTOMERS_DOCUMENTS_ADD , value);
}

// DELETE API 
export const CUSTOMERS_DOCUMENTS_DELETE = "customers/documents/delete/"
export function deleteCustomersDocumentsDelete(documentid:any,customerid:any) {
  return Axios.delete(CUSTOMERS_DOCUMENTS_DELETE+ customerid +"/"+documentid);
}




// STATISTICS API START 

export const VEHICLE_STATISTICS_LIST = "vehicle/statistics/list"
export function getVehicleStatisticsList() {
  return Axios.get(VEHICLE_STATISTICS_LIST);
}

export function RepairVehicleList() {
  return Axios.get(VEHICLE_STATISTICS_LIST + "?total_Repair_vehicle");
}
export function RepairVehicleCompleteList() {
  return Axios.get(VEHICLE_STATISTICS_LIST + "?repair_completed");
}
export function RepairVehicleAllList() {
  return Axios.get(VEHICLE_STATISTICS_LIST + "?repair_in_progress");
}
export function RentalYesterdayList() {
  return Axios.get(VEHICLE_STATISTICS_LIST + "?total_yesterday_Rental");
}
export function RentalTodayList() {
  return Axios.get(VEHICLE_STATISTICS_LIST + "?total_today_Rental");
}
export function RentalTommorrowList() {
  return Axios.get(VEHICLE_STATISTICS_LIST + "?total_tomorrow_Rental");
}


export const VEHICLE_INSURANCE_STATISTICS_LIST = "vehicle/statistics/insurance_list"
export function getVehicleInsuranceStatisticsList() {
  return Axios.get(VEHICLE_INSURANCE_STATISTICS_LIST);
}
export const VEHICLE_FINANCE_STATISTICS_LIST = "vehicle/statistics/finance_list"
export function getVehicleFinanceStatisticsList() {
  return Axios.get(VEHICLE_FINANCE_STATISTICS_LIST);
}


// Add Site 

export const SITE_ADD = "site/add"
export function addSite(value:any) {
  return Axios.post(SITE_ADD, value);
}

export const SITE_LIST = "site/list";
export const SITE_LIST_DATATABLE = SITE_LIST + "?company_id=" + COMPANY_ID + "&time_zone=" + TIME_ZONE;

export const GET_SITE_LIST = "site/list"
export function getSiteList() {
  return Axios.get(GET_SITE_LIST+"?page_size=1000");
}

export const GET_SITE_ID = "site/get/"
export function getSiteId(id:any) {
  return Axios.get(GET_SITE_ID+id);
}

export const SITE_UPDATE = "site/update/"
export function updateSite(id:any, value:any) {
  return Axios.patch(SITE_UPDATE+id, value);
}

export const SITE_DELETE = "site/delete/"
export function deleteSite(id:any) {
  return Axios.delete(SITE_DELETE+id);
}

// STATISTICS API END 

// Insurance Options

export const INSURANCE_OPTIONS_ADD = "insurance_options/add"
export function addInsuranceOptions(value:any) {
  return Axios.post(INSURANCE_OPTIONS_ADD, value);
}


export const GET_INSURANCE_OPTIONS = "insurance_options/get/"
export function getInsuranceOptions(id:any) {
  return Axios.get(GET_INSURANCE_OPTIONS + id);
}

export const INSURANCE_OPTIONS_LIST = "insurance_options/list"
export function InsuranceOptionsList() {
  return Axios.get(INSURANCE_OPTIONS_LIST);
}

export const INSURANCE_OPTIONS_UPDATE = "insurance_options/update/"
export function updateInsuranceOptions(id:any, value:any) {
  return Axios.patch(INSURANCE_OPTIONS_UPDATE+id, value);
}

export const INSURANCE_OPTIONS_DELETE = "insurance_options/delete/"
export function deleteInsuranceOptions(id:any) {
  return Axios.delete(INSURANCE_OPTIONS_DELETE+id);
}

// Additional Service

export const ADDITIONAL_SERVICE_ADD = "additional-service/add"
export function addAdditionalService(value:any) {
  return Axios.post(ADDITIONAL_SERVICE_ADD, value);
}


export const GET_ADDITIONAL_SERVICE = "additional-service/get/"
export function getAdditionalService(id:any) {
  return Axios.get(GET_ADDITIONAL_SERVICE + id);
}

export const ADDITIONAL_SERVICE_LIST = "additional-service/list"
export function AdditionalServiceList() {
  return Axios.get(ADDITIONAL_SERVICE_LIST);
}

export const ADDITIONAL_SERVICE_UPDATE = "additional-service/update/"
export function updateAdditionalService(id:any, value:any) {
  return Axios.patch(ADDITIONAL_SERVICE_UPDATE+id, value);
}

export const ADDITIONAL_SERVICE_DELETE = "additional-service/delete/"
export function deleteAdditionalService(id:any) {
  return Axios.delete(ADDITIONAL_SERVICE_DELETE+id);
}

// Rental

export const RENTAL_ADD = "rental/add"
export function addRental(value:any) {
  return Axios.post(RENTAL_ADD, value);
}

export const GET_RENTAL = "rental/get/"
export function getRental(id:any) {
  return Axios.get(GET_RENTAL + id);
}

export const RENTAL_LIST = "rental/list"
export function RentalList() {
  return Axios.get(RENTAL_LIST);
}

export const RENTAL_CUSTOMER_LIST = "rental/list?customer_id"

export function RentalCustomerList() {
  return Axios.get(RENTAL_LIST+"?customer_id");
}
// export function RentalYesterdayList() {
//   return Axios.get(RENTAL_LIST);
// }
// export function RentalTodayList() {
//   return Axios.get(RENTAL_LIST);
// }
// export function RentalTommorrowList() {
//   return Axios.get(RENTAL_LIST);
// }

export const RENTAL_UPDATE = "rental/update/"
export function updateRental(id:any, value:any) {
  return Axios.patch(RENTAL_UPDATE+id, value);
}

export const RENTAL_DELETE = "rental/delete/"
export function deleteRental(id:any) {
  return Axios.delete(RENTAL_DELETE+id);
}

export const RENTAL_BOOKING_STATUS_UPDATE = "rental/booking/status/update/"
export function updateRentalBookingStatus(id:any, value:any) {
  return Axios.patch(RENTAL_BOOKING_STATUS_UPDATE+id, value);
}

export const RENTAL_BOOKING_STATUS_LIST = "rental/booking/status/list";
export function rentalBookingStatusList(){
  return Axios.get(RENTAL_BOOKING_STATUS_LIST)
}

// owners
export const CUSTOMERS_IMAGE_DELETE = "customers/document_image/delete/"
export function deleteCustomersImage(id:any) {
  return Axios.delete(CUSTOMERS_IMAGE_DELETE+ id + "?type=licence_back_image");
}
export const CUSTOMERS_IMAGE_FRONT = "customers/document_image/delete/"
export function deleteCustomersFront(id:any) {
  return Axios.delete(CUSTOMERS_IMAGE_FRONT+ id + "?type=licence_front_image");
}
export const CUSTOMERS_AGREEMENT_FRONT = "customers/document_image/delete/"
export function deleteAgreementFront(id:any) {
  return Axios.delete(CUSTOMERS_AGREEMENT_FRONT+ id + "?type=agreement_front_image");
}
export const CUSTOMERS_AGREEMENT_BACK = "customers/document_image/delete/"
export function deleteAgreementBack(id:any) {
  return Axios.delete(CUSTOMERS_AGREEMENT_BACK+ id + "?type=agreement_back_image");
}
//RentalInsuranceOption

export const RENTAL_INSURANCE_OPTION_ADD = "rental-insurance-option/add"
export function addRentalInusuranceOption(value:any) {
  return Axios.post(RENTAL_INSURANCE_OPTION_ADD, value);
}

export const GET_RENTAL_INSURANCE_OPTION = "rental-insurance-option/get/"
export function getRentalInusuranceOption(id:any,rental_id:any) {
  return Axios.get(GET_RENTAL_INSURANCE_OPTION + id+ "?rental=" + rental_id);
}

export const RENTAL_INSURANCE_OPTION_LIST = "rental-insurance-option/list"
export function RentalInusuranceOptionList() {
  return Axios.get(RENTAL_INSURANCE_OPTION_LIST);
}

export const RENTAL_INSURANCE_OPTION_UPDATE = "rental-insurance-option/update/"
export function updateRentalInusuranceOption(id:any, value:any) {
  return Axios.patch(RENTAL_INSURANCE_OPTION_UPDATE+id, value);
}

export const RENTAL_INSURANCE_OPTION_DELETE = "rental-insurance-option/delete/"
export function deleteRentalInusuranceOption(id:any) {
  return Axios.delete(RENTAL_INSURANCE_OPTION_DELETE+id);
}


//RentalAdditionalService

export const RENTAL_ADDTIONAL_SERVICE_ADD = "rental-additional-service/add"
export function addRentalAdditionalService(value:any) {
  return Axios.post(RENTAL_ADDTIONAL_SERVICE_ADD, value);
}

export const GET_RENTAL_ADDTIONAL_SERVICE = "rental-additional-service/get/"
export function getRentalAdditionalService(id:any,rental_id:any) {
  return Axios.get(GET_RENTAL_ADDTIONAL_SERVICE + id +"?rental=" + rental_id);
}

export const RENTAL_ADDTIONAL_SERVICE_LIST = "rental-additional-service/list"
export function RentalAdditionalServiceList() {
  return Axios.get(RENTAL_ADDTIONAL_SERVICE_LIST);
}

export const RENTAL_ADDTIONAL_SERVICE_UPDATE = "rental-additional-service/update/"
export function updateRentalAdditionalService(id:any, value:any) {
  return Axios.patch(RENTAL_ADDTIONAL_SERVICE_UPDATE+id, value);
}

export const RENTAL_ADDTIONAL_SERVICE_DELETE = "rental-additional-service/delete/"
export function deleteRentalAdditionalService(id:any) {
  return Axios.delete(RENTAL_ADDTIONAL_SERVICE_DELETE+id);
}


//RentalClosingNotes

export const RENTAL_CLOSING_NOTES_ADD = "rental/closing-notes/add"
export function addRentalClosingNotes(value:any) {
  return Axios.post(RENTAL_CLOSING_NOTES_ADD, value);
}

export const GET_RENTAL_CLOSING_NOTES = "rental/closing-notes/get/"
export function getRentalClosingNotes(id:any,rental_id:any) {
  return Axios.get(GET_RENTAL_CLOSING_NOTES + id + "?rental=" + rental_id);
}

export const RENTAL_CLOSING_NOTES_LIST = "rental/closing-notes/list"
export function RentalClosingNotesList() {
  return Axios.get(RENTAL_CLOSING_NOTES_LIST);
}

export const RENTAL_CLOSING_NOTES_UPDATE = "rental/closing-notes/update/"
export function updateRentalClosingNotes(id:any, value:any) {
  return Axios.patch(RENTAL_CLOSING_NOTES_UPDATE+id, value);
}

export const RENTAL_CLOSING_NOTES_DELETE = "rental/closing-notes/delete/"
export function deleteRentalClosingNotes(id:any) {
  return Axios.delete(RENTAL_CLOSING_NOTES_DELETE+id);
}


//PaymentTransaction

export const RENTAL_PAYMENT_ADD = "rental/payment/add"
export function addRentalPayment(value:any) {
  return Axios.post(RENTAL_PAYMENT_ADD, value);
}



export const RENTAL_PAYMENT_LIST = "rental/payment/list"
export function RentalPayment() {
  return Axios.get(RENTAL_PAYMENT_LIST);
}


export const RENTAL_PAYMENT_DELETE = "rental/payment/delete/"
export function deleteRentalPayment(id:any) {
  return Axios.delete(RENTAL_PAYMENT_DELETE+id);
}

//Payment

export const VEHICLE_INSURANCE_FINANCE_PAYMENT_LIST = "vehicle/payment/list"
export function VehicleInsuranceFinancePayment() {
  return Axios.get(VEHICLE_INSURANCE_FINANCE_PAYMENT_LIST);
}
export const VEHICLE_PAYMENT_STATUS_UPDATE = "vehicle/payment/update-status/"
export function updateVehiclePaymentStatus(id:any, value:any) {
  return Axios.patch(VEHICLE_PAYMENT_STATUS_UPDATE+id, value);
}

//Vehicle insuranceInformation

export const INSURANCE_INFORMATION_ADD = "vehicle/insurance-information/add"
export function addInsuranceInformation(value:any) {
  return Axios.post(INSURANCE_INFORMATION_ADD, value);
}

export const INSURANCE_INFORMATION_DELETE = "vehicle/insurance-information/delete/"
export function deleteInsuranceInformation(id:any) {
  return Axios.delete(INSURANCE_INFORMATION_DELETE+id);
}
export const INSURANCE_INFORMATION_LIST = "vehicle/insurance-information/list"
export function listInsuranceInformation() {
  return Axios.get(INSURANCE_INFORMATION_LIST);
}
export const FINANCE_INFORMATION_DELETE = "vehicle/finance-information/delete/"
export function deleteFinanceInformation(id:any) {
  return Axios.delete(FINANCE_INFORMATION_DELETE+id);
}

export const FINANCE_INFORMATION_LIST = "vehicle/finance-information/list"
export function listFinanceInformation() {
  return Axios.get(VEHICLE_INSURANCE_FINANCE_PAYMENT_LIST);
}


// GPS

export const GPS_ADD = "gps/add"
export function addGps(value:any) {
  return Axios.post(GPS_ADD, value);
}


export const GET_GPS = "gps/get/"
export function getGps(id:any) {
  return Axios.get(GET_GPS + id);
}
export const GPS_LIST = "gps/list"
export function GpsList() {
  return Axios.get(GPS_LIST);
}


export const GPS_UPDATE = "gps/update/"
export function updateGps(id:any, value:any) {
  return Axios.patch(GPS_UPDATE+id, value);
}

export const GPS_DELETE = "gps/delete/"
export function deleteGps(id:any) {
  return Axios.delete(GPS_DELETE+id);
}


// maintenance

export const MAINTENANCE_ADD = "maintenance/add"
export function addMaintenance(value:any) {
  return Axios.post(MAINTENANCE_ADD, value);
}


export const GET_MAINTENANCE = "maintenance/get/"
export function getMaintenance(id:any) {
  return Axios.get(GET_MAINTENANCE + id);
}
export const MAINTENANCE_LIST = "maintenance/list"
export function MaintenanceList() {
  return Axios.get(MAINTENANCE_LIST);
}


export const MAINTENANCE_UPDATE = "maintenance/update/"
export function updateMaintenance(id:any, value:any) {
  return Axios.patch(MAINTENANCE_UPDATE+id, value);
}

export const MAINTENANCE_DELETE = "maintenance/delete/"
export function deleteMaintenance(id:any) {
  return Axios.delete(MAINTENANCE_DELETE+id);
}


// vicroads

export const VICROADS_ADD = "vicroads/add"
export function addVicroads(value:any) {
  return Axios.post(VICROADS_ADD, value);
}

export const GET_VICROADS = "vicroads/get/"
export function getVicroads(id:any) {
  return Axios.get(GET_VICROADS + id);
}
export const VICROADS_LIST = "vicroads/list"
export function VicroadsList() {
  return Axios.get(VICROADS_LIST);
}

export const VICROADS_UPDATE = "vicroads/update/"
export function updateVicroads(id:any, value:any) {
  return Axios.patch(VICROADS_UPDATE+id, value);
}

export const VICROADS_DELETE = "vicroads/delete/"
export function deleteVicroads(id:any) {
  return Axios.delete(VICROADS_DELETE+id);
}



//Documents
export const DOCUMENT_ADD = "document/add"
export function addDocument(value:any) {
  return Axios.post(DOCUMENT_ADD, value);
}
export const DOCUMENT_UPDATE = "document/update/"
export function updateDocument(id:any, value:any) {
  return Axios.patch(DOCUMENT_UPDATE+id, value);
}

export const DOCUMENT_GET = "document/get/"
export function getDocument(id:any) {
  return Axios.get(DOCUMENT_GET+id);
}

export const DOCUMENT_DELETE = "document/delete/"
export function deleteDocument(id:any) {
  return Axios.delete(DOCUMENT_DELETE+id);
}

export const DOCUMENTS_LIST = "document/list"
export function getDocumentsList(id:any) {
  return Axios.get(DOCUMENTS_LIST+"?customer_id="+id+"&page_size=1000");
}


//RepairVehicle
export const REPAIR_VEHICLE_ADD = "vehicle-repair/add"
export function addRepairVehicle(value:any) {
  return Axios.post(REPAIR_VEHICLE_ADD, value);
}
export const REPAIR_VEHICLE_UPDATE = "vehicle-repair/update/"
export function updateRepairVehicle(id:any, value:any) {
  return Axios.patch(REPAIR_VEHICLE_UPDATE+id, value);
}

export const REPAIR_VEHICLE_GET = "vehicle-repair/get/"
export function getRepairVehicle(id:any) {
  return Axios.get(REPAIR_VEHICLE_GET+id);
}

export function viewRepairVehicle(id:any) {
  return Axios.get(REPAIR_VEHICLE_GET+id);
}

export const REPAIR_VEHICLE_DELETE = "vehicle-repair/delete/"
export function deleteRepairVehicle(id:any) {
  return Axios.delete(REPAIR_VEHICLE_DELETE+id);
}

export const REPAIR_VEHICLE_LIST = "vehicle-repair/list"
// export function RepairVehicleList() {
//   return Axios.get(REPAIR_VEHICLE_LIST);
// }


export const REPAIR_VEHICLE_ALL_LIST = "vehicle-repair/list?repair_status=All"
// export function RepairVehicleAllList() {
//   return Axios.get(REPAIR_VEHICLE_ALL_LIST);
// }

export function RepairVehicleProgressList() {
  return Axios.get(REPAIR_VEHICLE_LIST+"?repair_in_progress");
}

export const REPAIR_VEHICLE_COMPLETE_STATUS_LIST = "vehicle-repair/list?repair_status=Completed"
// export function RepairVehicleCompleteList() {
//   return Axios.get(REPAIR_VEHICLE_COMPLETE_STATUS_LIST );
// }

export const REPAIR_VEHICLE_STATUS_LIST = "vehicle-repair/status/list";
export function RepairVehicleStatusList(){
  return Axios.get(REPAIR_VEHICLE_STATUS_LIST)
}

export const REPAIR_VEHICLE_FAULT_STATUS_LIST = "repair/fault-status";
export function RepairVehicleFaultStatusList(){
  return Axios.get(REPAIR_VEHICLE_FAULT_STATUS_LIST)
}

export const REPAIR_VEHICLE_STATUS_UPDATE = "vehicle-repair/status/update/"
export function updateRepairVehicleStatus(id:any, value:any) {
  return Axios.patch(REPAIR_VEHICLE_STATUS_UPDATE+id, value);
}

// export function RepairVehicleAllList() {
//     return Axios.get(REPAIR_VEHICLE_STATUS_UPDATE +"?repair_status=All");
//   }

//ReplacementVehicle

export const REPLACEMENT_VEHICLE_ADD = "replace-vehicle/add"
export function addReplacementVehicle(value:any) {
  return Axios.post(REPLACEMENT_VEHICLE_ADD, value);
}

export const GET_REPLACEMENT_VEHICLE = "replace-vehicle/get/"
export function getReplacementVehicle(id:any) {
  return Axios.get(GET_REPLACEMENT_VEHICLE + id);
}

export function viewReplacementVehicle(id:any) {
  return Axios.get(GET_REPLACEMENT_VEHICLE + id);
}

export const  REPLACEMENT_VEHICLE_LIST = "replace-vehicle/list"
export function RentalReplacementVehicleList() {
  return Axios.get(REPLACEMENT_VEHICLE_LIST);
}

export const  REPLACEMENT_VEHICLE_UPDATE = "replace-vehicle/update/"
export function updateReplacementVehicle(id:any, value:any) {
  return Axios.patch(REPLACEMENT_VEHICLE_UPDATE+id, value);
}

export const  REPLACEMENT_VEHICLE_DELETE = "replace-vehicle/delete/"
export function deleteReplacementVehiclen(id:any) {
  return Axios.delete(REPLACEMENT_VEHICLE_DELETE+id);
}

//RepairWorks

export const REPAIR_WORKS_ADD = "repair-works/add"
export function addRepairWorks(value:any) {
  return Axios.post(REPAIR_WORKS_ADD, value);
}

export const GET_REPAIR_WORKS = "repair-works/get/"
export function getRepairWorks(id:any) {
  return Axios.get(GET_REPAIR_WORKS + id);
}

export const REPAIR_WORKS_LIST = "repair-works/list"
export function RepairWorksList() {
  return Axios.get(REPAIR_WORKS_LIST);
}

export const  REPAIR_WORKS_UPDATE = "repair-works/update/"
export function updateRepairWorks(id:any, value:any) {
  return Axios.patch(REPAIR_WORKS_UPDATE+id, value);
}

export const  REPAIR_WORKS_DELETE = "repair-works/delete/"
export function deleteRepairWorks(id:any) {
  return Axios.delete(REPAIR_WORKS_DELETE+id);
}

//RepairPaymentTransaction

export const REPAIR_PAYMENT_ADD = "repair/payment/add"
export function addRepairPayment(value:any) {
  return Axios.post(REPAIR_PAYMENT_ADD, value);
}



export const REPAIR_PAYMENT_LIST = "repair/payment/list"
export function RepairPaymentList() {
  return Axios.get(REPAIR_PAYMENT_LIST);
}


export const REPAIR_PAYMENT_DELETE = "repair/payment/delete/"
export function deleteRepairPayment(id:any) {
  return Axios.delete(REPAIR_PAYMENT_DELETE+id);
}